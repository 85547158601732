import React, { useState, useEffect, useRef } from 'react';
import tw, { css } from 'twin.macro';
import Portal from './portal';
import Simplebar from 'simplebar-react';
import Sandbox from '../atoms/sandbox';
import Action from '../atoms/action';
import {
  enableScrollLock,
  clearScollLock,
} from '../../utils/use-body-scroll-lock';
import CloseIcon from '../../images/icons/close.inline.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { hasMessage, setMessage } from '../../utils/cookie-utils';
import { useCallback } from 'react';

const PreviewAction = tw(Action)`m-1`;

const EntryMessage = ({ title, subtitle, uid, image, actions, text }) => {
  const [show, setShow] = useState(true);
  const ref = useRef();
  const localImage = image?.localFile && getImage(image.localFile);

  const close = useCallback(() => {
    setMessage(uid);
    setShow(false);
    clearScollLock();
  }, [uid]);

  useEffect(() => {
    if (!hasMessage(uid)) {
      setShow(true);
      const tid = setTimeout(() => {
        enableScrollLock(ref.current?.getScrollElement?.() ?? true);
      }, 100);
      return () => {
        clearTimeout(tid);
        close();
      };
    }
  }, [uid, close]);
  return (
    <Portal>
      <AnimatePresence>
        {show && (
          <motion.div
            tw="fixed inset-0 w-full flex items-center justify-center"
            css={[`z-index: 200`]}
            initial="out"
            animate="in"
            exit="exit"
            variants={{
              exit: {
                transition: {
                  staggerDirection: -1,
                },
              },
            }}
            transition={{
              staggerChildren: 0.1,
            }}
          >
            <motion.div
              variants={{
                out: { opacity: 0 },
                in: { opacity: 1 },
                exit: { opacity: 0 },
              }}
              tw="fixed inset-0 w-full bg-brand-purple-400 bg-opacity-60"
            ></motion.div>
            <motion.div
              css={[
                tw`w-10/12 max-w-screen-sm px-2 text-center bg-white relative z-50`,
              ]}
              className="overflow-fade-white"
              variants={{
                out: { scale: 0, opacity: 0 },
                in: { scale: 1, opacity: 1 },
                exit: { y: '200%', opacity: 0 },
              }}
            >
              <Simplebar
                autoHide={true}
                ref={ref}
                css={[
                  tw`overflow-y-auto overflow-x-hidden`,
                  css`
                    max-height: 70vh;
                    .simplebar-scrollbar:before {
                      ${tw`bg-brand-purple-200`}
                    }
                  `,
                ]}
              >
                <div tw="px-6 py-12">
                  {localImage && (
                    <GatsbyImage
                      tw="absolute bottom-0 left-0 w-full"
                      image={localImage}
                      alt={image.alternativeText}
                    />
                  )}
                  <div tw="relative">
                    {title && (
                      <h3 tw="font-bold text-2xl leading-none text-brand-green">
                        {title}
                      </h3>
                    )}
                    {subtitle && <h4 tw="font-light mt-2">{subtitle}</h4>}
                    {text && <Sandbox html={text} tw="mt-4" />}
                    {actions && actions.length > 0 && (
                      <div tw="flex items-center flex-wrap justify-center mt-6 -mx-1">
                        {actions.map((action) => (
                          <PreviewAction
                            key={action.id}
                            data={action}
                            onClick={close}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </Simplebar>
              <button
                tw="absolute top-0 right-0 rounded-full bg-brand-purple-800 text-brand-purple-100 focus:outline-none z-20 p-3 -mr-4 -mt-4"
                onClick={close}
              >
                <CloseIcon />
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default EntryMessage;
